import { GetSelectOptionLabelHandler, SelectOption } from '@platform-ui-kit/components-library'
import { WppSelect, WppListItem, WppDivider, WppSpinner } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { forwardRef, Ref, ReactNode, ComponentProps, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import styles from 'components/common/select/Select.module.scss'
import { getKey, useSelectDropdownConfig } from 'components/common/select/utils'
import { useCommonLabelProps } from 'components/common/utils'

type WppSelectProps = ComponentProps<typeof WppSelect>

export interface SelectProps extends WppSelectProps {
  options: SelectOption[]
  isOptionDisabled?: (option: SelectOption) => boolean
  getOptionValue?: (option: SelectOption) => SelectOption
  getOptionLabel?: GetSelectOptionLabelHandler
  getOptionKey?: (option: SelectOption) => string
  renderOptionContent?: (option: SelectOption) => ReactNode
  customOption?: JSX.Element
  listItemStyle?: string
  infinite?: boolean
  search?: string
  'data-testid'?: string
}

export const Select = forwardRef(function Select(
  {
    options,
    dropdownPosition = 'fixed',
    getOptionValue = option => option.value,
    getOptionLabel = option => option.label,
    getOptionKey = option => getKey(option),
    renderOptionContent,
    isOptionDisabled = () => false,
    'data-testid': dataTestId,
    labelConfig,
    labelTooltipConfig,
    locales,
    dropdownConfig,
    className,
    customOption,
    withSearch,
    withFolder,
    search,
    loading,
    listItemStyle,
    infinite = false,
    ...rest
  }: SelectProps,
  ref: Ref<HTMLWppSelectElement>,
) {
  const { t } = useTranslation()
  const labelProps = useCommonLabelProps({ labelConfig, labelTooltipConfig })
  const dropdownConfigInner = useSelectDropdownConfig(dropdownConfig)

  const defaultLocales = useMemo<NonNullable<WppSelectProps['locales']>>(
    () => ({
      emptyText: t('os.common.select.empty_text'),
      clearAllText: t('os.common.select.clear_all_text'),
      selectAllText: t('os.common.select.select_all_text'),
      searchInputPlaceholder: t('os.common.select.search_input_placeholder'),
      allSelectedText: t('os.common.select.all_selected_text'),
      selectLabel: t('os.common.select.select_label'),
    }),
    [t],
  )

  return (
    <WppSelect
      ref={ref}
      {...rest}
      {...labelProps}
      withSearch={withSearch}
      withFolder={withFolder && !!options.length}
      // TODO WPPLONOP-19180
      className={clsx(
        styles.select,
        { [styles.withSearch]: withSearch, [styles.showJustClearAll]: infinite },
        className,
      )}
      infinite={infinite}
      loading={loading}
      dropdownPosition={dropdownPosition}
      locales={locales ? { ...defaultLocales, ...locales } : defaultLocales}
      dropdownConfig={dropdownConfigInner}
      data-testid={dataTestId}
    >
      {!!customOption && !search && (
        <>
          {customOption}
          <WppDivider className={styles.divider} />
        </>
      )}
      {options.map(option => {
        const optionValue = getOptionValue(option)

        return (
          <WppListItem
            // listItemStyle - to add posibility show tooltip on disabled items
            className={listItemStyle}
            key={getOptionKey(option)}
            value={optionValue}
            disabled={isOptionDisabled(option)}
          >
            {renderOptionContent ? renderOptionContent?.(option) : <span slot="label">{getOptionLabel(option)}</span>}
          </WppListItem>
        )
      })}
      {/* TODO: WPPLONOP-200048 */}
      {!options.length && !loading && infinite && (
        <WppListItem disabled multiple={false}>
          <span slot="label">{t('os.common.select.empty_text')}</span>
        </WppListItem>
      )}
      {loading && (!!search || !options.length) && infinite && (
        <WppListItem disabled multiple={false} className={styles.loading}>
          <WppSpinner slot="label" />
        </WppListItem>
      )}
    </WppSelect>
  )
})
