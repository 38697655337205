import { lazy } from 'react'
import { Outlet, Route } from 'react-router-dom'

import { SuspenseLoader } from 'components/common/SuspenseLoader'
import {
  TENANT_NAME_EASY_JET,
  TENANT_NAME_WPP_PITCH_OS,
  TENANT_SUBDOMAIN_NESTLE,
  TENANT_SUBDOMAIN_WPP,
  TENANT_SUBDOMAIN_LVMH_APAC,
  TENANT_SUBDOMAIN_UNILEVER,
  TENANT_SUBDOMAIN_AUDIBLE,
} from 'constants/common'
import { TenantHostInfo } from 'types/tenants/tenant'

const EasyJetProcess = lazy(() => import('pages/easyJetProcess/EasyJetProcess'))
const PitchPlaybook = lazy(() => import('pages/wppPitchOsDashboard/pages/pitchPlaybook/PitchPlaybook'))
const NestleDemo = lazy(() => import('pages/wppOpenDashboard/pages/nestleDemo/NestleDemo'))
const VolkswagenDemo = lazy(() => import('pages/wppOpenDashboard/pages/volkswagenDemo/VolkswagenDemo'))
const BeiersdorfDemo = lazy(() => import('pages/wppOpenDashboard/pages/beiersdorfDemo/BeiersdorfDemo'))
const SpotifyDemo = lazy(() => import('pages/wppOpenDashboard/pages/spotifyDemo/SpotifyDemo'))
const MindshareDemo = lazy(() => import('pages/wppOpenDashboard/pages/mindshareDemo/MindshareDemo'))
const MSixDemo = lazy(() => import('pages/wppOpenDashboard/pages/mSixDemo/MSixDemo'))
const LvmhProcess = lazy(() => import('pages/pitch/process/lvmh/LvmhProcess'))
const DiscoverLvmh = lazy(() => import('pages/pitch/process/lvmh/processPhases/discoverLvmh/DiscoverLvmh'))
const DesignLvmh = lazy(() => import('pages/pitch/process/lvmh/processPhases/designLvmh/DesignLvmh'))
const DeliverLvmh = lazy(() => import('pages/pitch/process/lvmh/processPhases/deliverLvmh/DeliverLvmh'))
const UnileverDemo = lazy(() => import('pages/wppOpenDashboard/pages/unileverDemo/UnileverDemo'))
const PitchSupport = lazy(() => import('pages/wppOpenDashboard/pages/pitchSupport/PitchSupport'))
const AldiDemo = lazy(() => import('pages/wppOpenDashboard/pages/aldi/AldiDemo'))
const AllianzDemo = lazy(() => import('pages/wppOpenDashboard/pages/allianz/AllianzDemo'))
const TcccDemo = lazy(() => import('pages/wppOpenDashboard/pages/tccc/TcccDemo'))
const AudibleProcess = lazy(() => import('pages/pitch/process/audible/AudibleProcess'))
const ProvokeAudible = lazy(() => import('pages/pitch/process/audible/processPhases/pages/provoke/Provoke'))
const StrategizeAudible = lazy(() => import('pages/pitch/process/audible/processPhases/pages/strategize/Strategize'))
const PlanAudible = lazy(() => import('pages/pitch/process/audible/processPhases/pages/plan/Plan'))
const ActivateAudible = lazy(() => import('pages/pitch/process/audible/processPhases/pages/activate/Activate'))
const LearnAudible = lazy(() => import('pages/pitch/process/audible/processPhases/pages/learn/Learn'))

export const getHardCodedRoutes = ({ currentTenant }: TenantHostInfo) => {
  const { name, subdomain } = currentTenant

  // TODO [WPPLONOP-13902]: Hardcoded by tenant name
  const isProcessPageEnabled = name === TENANT_NAME_EASY_JET
  // TODO [WPPLONOP-15160]: Hardcoded by tenant name
  const isWppPitchOsPageEnabled = name === TENANT_NAME_WPP_PITCH_OS
  const isWppOpenPageEnabled = subdomain === TENANT_SUBDOMAIN_WPP
  const isNestlePageEnabled = subdomain === TENANT_SUBDOMAIN_NESTLE
  const isLvmhProcessPageEnabled = subdomain === TENANT_SUBDOMAIN_LVMH_APAC
  const isUnileverProcessPageEnabled = subdomain === TENANT_SUBDOMAIN_UNILEVER
  const isAudibleProcessPageEnabled = subdomain === TENANT_SUBDOMAIN_AUDIBLE

  // NOTE: We don't care about inner routing calculation for AppRouter for this pages.
  // Child routes are hanled by <Routes> inside each page.
  return (
    <Route
      element={
        <SuspenseLoader>
          <Outlet />
        </SuspenseLoader>
      }
    >
      {isWppPitchOsPageEnabled && <Route path="pitch-playbook" element={<PitchPlaybook />} />}
      {isProcessPageEnabled && <Route path="process" element={<EasyJetProcess />} />}
      {isNestlePageEnabled && <Route path="process/*" element={<NestleDemo />} />}
      {isUnileverProcessPageEnabled && <Route path="process/*" element={<UnileverDemo />} />}

      {isLvmhProcessPageEnabled && (
        <Route path="process">
          <Route index path="*" element={<LvmhProcess />} />
          <Route path="lvmh">
            <Route path="discover" element={<DiscoverLvmh />} />
            <Route path="design" element={<DesignLvmh />} />
            <Route path="deliver" element={<DeliverLvmh />} />
          </Route>
        </Route>
      )}

      {isAudibleProcessPageEnabled && (
        <Route path="process">
          <Route index path="*" element={<AudibleProcess />} />
          <Route path="audible">
            <Route path="provoke" element={<ProvokeAudible />} />
            <Route path="strategize" element={<StrategizeAudible />} />
            <Route path="plan" element={<PlanAudible />} />
            <Route path="activate" element={<ActivateAudible />} />
            <Route path="learn" element={<LearnAudible />} />
          </Route>
        </Route>
      )}

      {isWppOpenPageEnabled && (
        <>
          <Route path="nestle/*" element={<NestleDemo />} />
          <Route path="volkswagen/*" element={<VolkswagenDemo />} />
          <Route path="beiersdorf/*" element={<BeiersdorfDemo />} />
          <Route path="spotify/*" element={<SpotifyDemo />} />
          <Route path="mindshare/*" element={<MindshareDemo />} />
          <Route path="msix/*" element={<MSixDemo />} />
          <Route path="pitch-support/*" element={<PitchSupport />} />
          <Route path="aldi/*" element={<AldiDemo />} />
          <Route path="allianz/*" element={<AllianzDemo />} />
          <Route path="tccc/*" element={<TcccDemo />} />
        </>
      )}
    </Route>
  )
}
