import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { EventName, Feed } from 'types/feeds/feeds'

export function useNotificationMessage() {
  const { t } = useTranslation()

  const getToastMessage = useCallback(
    (notification: Feed) => {
      const { context, eventName } = notification
      const { user_name, body, tenant_name } = context

      switch (eventName) {
        case EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN:
          return t('os.notification.access.hierarchy.requested_access', { userName: user_name })
        case EventName.HIERARCHY_ACCESS_REJECTED:
          return t('os.notification.access.rejected_access', {
            type: t('os.notification.access.hierarchy.navigation'),
          })
        case EventName.HIERARCHY_ACCESS_APPROVED:
          return t('os.notification.access.approved_access', {
            type: t('os.notification.access.hierarchy.navigation'),
          })
        case EventName.TENANT_ACCESS_REQUESTED_ADMIN:
          return t('os.notification.access.tenant.requested_access', {
            userName: user_name,
            tenantName: tenant_name,
          })
        case EventName.ADMIN_NOTIFICATION_REQUEST:
          return body
        case EventName.APPS_NOTIFICATION:
          return t('os.notification.apps.new_notification')
      }
    },
    [t],
  )

  const getToastHeader = useCallback((notification: Feed) => {
    const { context, eventName } = notification
    if (eventName === EventName.APPS_NOTIFICATION) {
      const { title } = context
      return title
    }
    return notification.categories[0]?.toUpperCase()
  }, [])

  return { getToastMessage, getToastHeader }
}
