import { OsContextActivePage, MayBeNull } from '@wpp-open/core'

import { CriticalError, ForbiddenPageError, NotFoundError } from 'components/renderError'
import { useHasPermission } from 'hooks/useHasPermission'
import { useSetActivePage } from 'hooks/useSetActivePage'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { LegacyMicroAppContainer } from 'legacy/LegacyMicroAppContainer'
import { MicroApp } from 'pages/appsNavigation/microApp/MicroApp'
import { NoCodeApp } from 'pages/appsNavigation/noCodeApp/NoCodeApp'
import { useMicroAppErrorHandler } from 'pages/appsNavigation/utils'
import { useOsState } from 'providers/osState/OsStateProvider'
import { MicroAppType, SystemAppCode } from 'types/apps/microApps'
import { AppData, AppDataType, InvalidAppData, LoadingAppData } from 'types/osState/appDataResolved'

export const AppsNavigation = () => {
  const { appDataDebug, appData } = useOsState()
  const { isMicroAppError } = useMicroAppErrorHandler()

  if (appData.type === AppDataType.Loading) {
    return <LoadingPage />
  }

  if (appData.type === AppDataType.Invalid) {
    console.warn('Invalid navigation url or app data', location, appDataDebug)

    return <NotFoundError />
  }

  return (
    <>
      {isMicroAppError && <CriticalError />}

      <RenderApp appData={appData} />
    </>
  )
}

interface RenderAppProps {
  appData: Exclude<AppData, InvalidAppData | LoadingAppData>
}

const RenderApp = ({ appData }: RenderAppProps) => {
  const { hasPermission } = useHasPermission()

  const { type, app } = appData

  const getCurrentPageBySystemAppStableId = (): MayBeNull<OsContextActivePage> => {
    if (app.type !== MicroAppType.System) {
      return null
    }
    switch (app.stableId) {
      case SystemAppCode.DevHub:
        return OsContextActivePage.Devhub
      case SystemAppCode.Orchestration:
        return OsContextActivePage.Orchestration
      case SystemAppCode.Marketplace:
        return OsContextActivePage.Marketplace
      case SystemAppCode.News:
        return OsContextActivePage.News

      default:
        return null
    }
  }

  useSetActivePage(getCurrentPageBySystemAppStableId())

  if (type === AppDataType.NoCodeApp) {
    return <NoCodeApp app={app} />
  }

  if (!hasPermission(app.permission)) {
    return <ForbiddenPageError />
  }

  return (
    <>
      {type === AppDataType.ExternalLegacyMicroApp || type === AppDataType.LocalLegacyMicroApp ? (
        <LegacyMicroAppContainer appData={appData} />
      ) : (
        <MicroApp appData={appData} />
      )}
    </>
  )
}
