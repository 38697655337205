import { AnalyticsActionType, OsContextActivePage } from '@wpp-open/core'
import { Outlet } from 'react-router-dom'
import { useEffectOnce } from 'react-use'

import { SuspenseLoader } from 'components/common/SuspenseLoader'
import { useSetActivePage } from 'hooks/useSetActivePage'
import styles from 'pages/home/homePageLayout/HomePageLayout.module.scss'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export const HomePageLayout = () => {
  useSetActivePage(OsContextActivePage.Home)
  useEffectOnce(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.HOME_PAGE.PAGE.OPEN,
    })
  })

  return (
    <div className={styles.root} data-testid="app-home-root">
      <SuspenseLoader>
        <Outlet />
      </SuspenseLoader>
    </div>
  )
}
