import {
  WppActionButton,
  WppCard,
  WppDivider,
  WppCheckbox,
  WppIconAvailableCheckmark,
  WppIconMore,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppSkeleton,
} from '@platform-ui-kit/components-library-react'
import { Tenant } from '@wpp-open/core'
import clsx from 'clsx'
import { t } from 'i18next'
import { useState } from 'react'

import { useDeleteFeedApi } from 'api/feeds/mutations/useDeleteFeedApi'
import { useUpdateFeedsApi } from 'api/feeds/mutations/useUpdateFeedsApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { showNotificationItemSideModal } from 'components/notificationItemSideModal/NotificationItemSideModal'
import styles from 'components/notificationsSideModal/notificationCard/NotificationCard.module.scss'
import {
  AppsNotificationCardContent,
  CustomNotificationCardContent,
  HierarchyAccessInfoCardContent,
  HierarchyRequestedCardContent,
  TenantRequestedCardContent,
} from 'components/notificationsSideModal/notificationCard/NotificationCardContent'
import { NotificationHeader } from 'components/notificationsSideModal/notificationHeader/NotificationHeader'
import { useNotificationFeedsState } from 'components/notificationsSideModal/utils'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOsRoot } from 'providers/osRoot/OsRootContext'
import { useToast } from 'providers/toast/ToastProvider'
import { Feed, EventName } from 'types/feeds/feeds'
import { TenantShort } from 'types/tenants/tenant'

interface Props {
  notification: Feed
  tenant: TenantShort | Tenant
}

export const NotificationCard = ({ notification, tenant }: Props) => {
  const { enqueueToast } = useToast()

  const { id, isRead, context, createdAt, eventName } = notification
  const { logoThumbnail, id: tenantId, name } = tenant
  const { tenant_name, user_name, request_reason, reject_reason, body, title } = context

  const [isAvatarHover, setIsAvatarHover] = useState(false)
  const { selectedFeeds, handleFeedSelect } = useNotificationFeedsState()

  const { defaultTenant } = useOsRoot()
  const { mutateAsync: handleUpdateFeeds } = useUpdateFeedsApi()
  const { mutateAsync: handleDeleteFeed } = useDeleteFeedApi()

  const logoUrl = logoThumbnail?.url || defaultTenant.logoThumbnail?.url
  const isCheckBoxVisible = isAvatarHover || selectedFeeds.includes(id)

  const feedsDataReloadHandler = async () => {
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE] })
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  }

  const handleCardClick = async () => {
    showNotificationItemSideModal({ notification, logoUrl })
    if (!isRead) {
      await handleUpdateFeeds({ tenantId, ids: [id], updates: { isRead: true } })
      await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE] })
    }
  }

  const handleReadStatus = async () => {
    await handleUpdateFeeds({ tenantId, ids: [id], updates: { isRead: !isRead } })
    await feedsDataReloadHandler()
  }

  const handleFeedDelete = async () => {
    try {
      await handleDeleteFeed({ tenantId, id })
      await feedsDataReloadHandler()
      enqueueToast({
        message: t('os.notification.toast.delete_success_one'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    }
  }

  return (
    <div className={styles.cardWrapper} data-testid="notification-card">
      <Flex
        className={styles.avatarWrapper}
        onMouseOver={() => setIsAvatarHover(true)}
        onMouseOut={() => setIsAvatarHover(false)}
      >
        {isCheckBoxVisible ? (
          <WppCheckbox
            name="isSelected"
            className={styles.checkBox}
            onWppChange={() => handleFeedSelect(id)}
            data-testid="notification-select-checkbox"
          />
        ) : (
          <Avatar
            size="m"
            name={name}
            src={logoUrl || undefined}
            className={styles.avatar}
            data-testid="notification-card-logo"
          />
        )}
      </Flex>

      <WppCard size="s" onClick={handleCardClick} className={styles.card}>
        <Flex direction="column" gap={4} className={styles.content}>
          <NotificationHeader createdAt={createdAt} title={title} />

          {eventName === EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN && (
            <HierarchyRequestedCardContent userName={user_name} />
          )}

          {(eventName === EventName.HIERARCHY_ACCESS_REJECTED || eventName === EventName.HIERARCHY_ACCESS_APPROVED) && (
            <HierarchyAccessInfoCardContent
              eventName={eventName}
              requestReason={request_reason}
              rejectReason={reject_reason}
            />
          )}

          {eventName === EventName.TENANT_ACCESS_REQUESTED_ADMIN && (
            <TenantRequestedCardContent userName={user_name} tenantName={tenant_name} />
          )}

          {eventName === EventName.ADMIN_NOTIFICATION_REQUEST && <CustomNotificationCardContent text={body} />}
          {eventName === EventName.APPS_NOTIFICATION && <AppsNotificationCardContent body={body} />}
        </Flex>
      </WppCard>

      <Flex className={styles.actionsWrapper} align="center" gap={8}>
        <WppMenuContext
          dropdownConfig={{
            appendTo: () => document.body,
            placement: 'bottom-end',
          }}
        >
          <WppActionButton
            slot="trigger-element"
            variant="secondary"
            className={styles.actionButton}
            data-testid="notification-card-actions"
          >
            <WppIconMore direction="horizontal" className={styles.moreIcon} />
          </WppActionButton>
          <Flex direction="column" gap={8}>
            <WppListItem onWppChangeListItem={() => handleReadStatus()}>
              <WppIconAvailableCheckmark slot="left" />
              <span slot="label">
                {t(isRead ? 'os.notification.actions.mark_as_unread' : 'os.notification.actions.mark_as_read')}
              </span>
            </WppListItem>
            <WppDivider />
            <WppListItem onWppChangeListItem={() => handleFeedDelete()}>
              <WppIconTrash slot="left" />
              <span slot="label">{t('os.notification.actions.delete')}</span>
            </WppListItem>
          </Flex>
        </WppMenuContext>
        <div className={clsx(styles.unread, !isRead && styles.visibile)} data-testid="notification-unread-indicator" />
      </Flex>
    </div>
  )
}

export const NotificationCardSkeleton = () => {
  return (
    <WppCard>
      <Flex gap={20}>
        <WppSkeleton width={40} height={40} />
        <Flex direction="column" gap={5}>
          <WppSkeleton width={200} height={15} />
          <WppSkeleton width={400} height={20} />
          <WppSkeleton width={300} height={20} />
        </Flex>
      </Flex>
    </WppCard>
  )
}
