import { WppButton, WppTypography } from '@platform-ui-kit/components-library-react'
import { useEffect } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { SvgForbidden } from 'components/svg/renderError/SvgForbidden'
import styles from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroBoardAppRenderer.module.scss'
import { useToast } from 'providers/toast/ToastProvider'
import { mailto } from 'utils/links'
import { routesManager } from 'utils/routesManager'

export const MiroRestrictedState = ({ email = '' }: { email?: string }) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  useEffect(() => {
    enqueueToast({
      header: t('os.lean_app_page.miro_restricted_state.toast_title'),
      message: t('os.lean_app_page.miro_restricted_state.toast_text'),
      type: 'error',
    })
  }, [t, enqueueToast])

  return (
    <Flex
      justify="center"
      align="center"
      direction="column"
      className={styles.fullHeight}
      gap={20}
      data-testid="miro-issue-page"
    >
      <SvgForbidden />
      <WppTypography type="5xl-display" className={styles.title}>
        {t('os.error_state.forbidden_page.main_title')}
      </WppTypography>
      <WppTypography type="s-body" className={styles.issueDescription} data-testid="access-denied">
        <Trans
          i18nKey="os.lean_app_page.miro_restricted_state.description"
          values={{
            email,
          }}
          components={[
            <a key="0" href={mailto(email)} className={styles.email}>
              email
            </a>,
          ]}
        />
      </WppTypography>
      <Link to={routesManager.home.root.url()} className={styles.button}>
        <WppButton data-testid="go-home-button">{t('os.error_state.forbidden_page.primary_button')}</WppButton>
      </Link>
    </Flex>
  )
}
