import { WppTypography } from '@platform-ui-kit/components-library-react'
import { getNavigationNodeType, HierarchyContainerNodeId } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import { useGetDevHubLogoUrl, useHierarchyNodeAppInstances } from 'components/hierarchyAppsManagement/utils'
import { AppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/appMenuItem/AppNavigationMenuItem'
import styles from 'components/navigationMenu/navigationMenuContent/appsNavigationMenuColumn/AppsNavigationMenuColumn.module.scss'
import { LocalAppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/localAppMenuItem/LocalAppNavigationMenuItem'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { AppsTreeList } from 'components/treeList/appsTreeList/AppsTreeList'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { getAppInstanceLogo } from 'utils/appInstances'
import { getWorkspacePointerNodes, useGetHierarchyLevelLabel } from 'utils/mapping/common'

export const AppsNavigationMenuColumn = () => {
  const { t } = useTranslation()
  const { navigationHierarchy } = useCurrentTenantData()
  const { nodesMapping } = useHeaderNavigation()
  const { selectedNavigationNodeId } = useNavigationMenuController()
  const getHierarchyLevelLabel = useGetHierarchyLevelLabel()

  const workspacePointerNodes = getWorkspacePointerNodes({
    pointerNode: nodesMapping[selectedNavigationNodeId],
    navigationHierarchy,
  })

  const parentPointerNode = workspacePointerNodes.at(-1) || nodesMapping[HierarchyContainerNodeId]
  const isHierarchyRoot = parentPointerNode.nodeId === HierarchyContainerNodeId
  const hasLocalApp = !!process.env.DEV

  const levelName = getHierarchyLevelLabel({
    levelType: workspacePointerNodes.length ? getNavigationNodeType(parentPointerNode.node) : null,
  })

  const { data: appInstances, isLoading: isAppInstancesLoading } = useHierarchyNodeAppInstances({
    params: {
      assignmentId: isHierarchyRoot ? parentPointerNode.parent?.nodeId : parentPointerNode.nodeId,
    },
  })
  const getDevHubLogoUrl = useGetDevHubLogoUrl(appInstances)

  const isLocalAppVisible = hasLocalApp && !isAppInstancesLoading

  const visibleAppInstances = appInstances.filter(app => app.flags.visible)

  return (
    <NavigationMenuTreeListColumn data-testid="apps-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="apps-navigation-menu-column-title">
          {t('os.navigation_menu.apps_header_text', { levelName })}
        </WppTypography>
      </NavigationMenuColumnHeader>

      <Flex as={FadeIn} direction="column" gap={2}>
        {isLocalAppVisible && (
          <LocalAppNavigationMenuItem workspaceId={!isHierarchyRoot ? parentPointerNode.nodeId : null} />
        )}

        <AppsTreeList
          isLoading={isAppInstancesLoading}
          appInstances={visibleAppInstances}
          renderApp={appInstance => (
            <AppNavigationMenuItem
              appInstance={appInstance}
              logoUrl={getAppInstanceLogo({ appInstance, getDevHubLogoUrl })}
            />
          )}
          emptyState={
            <>
              {!hasLocalApp && (
                <WppTypography className={styles.emptyState} type="s-midi" data-testid="no-apps-yet">
                  {t('os.navigation_menu.no_apps')}
                </WppTypography>
              )}
            </>
          }
        />
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}
