import { InputChangeEventDetail } from '@platform-ui-kit/components-library'
import {
  WppTypography,
  WppCardGroup,
  WppSpinner,
  WppPill,
  WppActionButton,
  WppIconMore,
  WppDivider,
  WppIconAvailableCheckmark,
  WppIconTrash,
  WppListItem,
  WppMenuContext,
  WppIconSearch,
  WppInput,
} from '@platform-ui-kit/components-library-react'
import { Tenant } from '@wpp-open/core'
import { RefCallback, useCallback, useMemo, useState, Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useInfiniteFetchFeedsApi } from 'api/feeds/infiniteQueries/useInfiniteFetchFeedsApi'
import { useDeleteBulkFeedsApi } from 'api/feeds/mutations/useDeleteBulkFeedsApi'
import { useMarkAsReadBulkApi } from 'api/feeds/mutations/useMarkAsReadBulkApi'
import { useUpdateFeedsLastSeenAtApi } from 'api/feeds/mutations/useUpdateFeedsLastSeenAtApi'
import { useFeedsStatusesApi } from 'api/feeds/queries/useFeedsStatusesApi'
import { Flex } from 'components/common/flex/Flex'
import { EmptySearchState } from 'components/notificationsSideModal/EmptySearchState'
import { EmptyState } from 'components/notificationsSideModal/EmptyState'
import { NotificationCardSkeleton } from 'components/notificationsSideModal/notificationCard/NotificationCard'
import { NotificationItems } from 'components/notificationsSideModal/notificationItems/NotificationItems'
import { NotificationsSegmentedControl } from 'components/notificationsSideModal/notificationsSegmentedControl/NotificationsSegmentedControl'
import styles from 'components/notificationsSideModal/NotificationsSideModal.module.scss'
import { TenantIconWithNotification } from 'components/notificationsSideModal/tenantIconWithNotification/TenantIconWithNotification'
import { NotificationFeedsStateContext, NotificationSegment } from 'components/notificationsSideModal/utils'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { Delay } from 'constants/delay'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useDebounceFn } from 'hooks/useDebounceFn'
import { useInfiniteFetchNextPage } from 'hooks/useInfiniteFetchNextPage'
import { useCurrentTenantData } from 'providers/currentTenantData/CurrentTenantDataContext'
import { queryClient } from 'providers/osQueryClient/utils'
import { useOtherTenantsAndUserData } from 'providers/otherTenantsAndUserData/OtherTenantsAndUserDataContext'
import { useToast } from 'providers/toast/ToastProvider'
import { TenantShort } from 'types/tenants/tenant'
import { normalize } from 'utils/common'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

const Skeleton = () =>
  Array(7)
    .fill('')
    .map((_, index) => <NotificationCardSkeleton key={index} />)

const NotificationsSideModal = ({ isOpen, onClose, onCloseComplete, id }: NiceModalWrappedProps) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  const [currentSegment, setCurrentSegment] = useState<NotificationSegment>(NotificationSegment.ALL)
  const { currentTenant } = useCurrentTenantData()
  const { mutateAsync: handleDeleteBulkFeeds } = useDeleteBulkFeedsApi()

  const [selectedTenant, setSelectedTenant] = useState<TenantShort | Tenant>(currentTenant)
  const [selectedFeeds, setSelectedFeeds] = useState<string[]>([])
  const [isSearchDisplayed, setIsSearchDisplayed] = useState<boolean>(false)
  const [search, setSearch] = useState<string>('')

  const { mutateAsync: handleUpdateFeedsLastSeenAt } = useUpdateFeedsLastSeenAtApi()
  const { mutateAsync: handleMarkAsRead } = useMarkAsReadBulkApi()
  const { data: statuses } = useFeedsStatusesApi()

  const { availableTenants } = useOtherTenantsAndUserData()

  const availableTenantsWithoutCurrent = availableTenants.filter(tenant => tenant.id !== currentTenant.id)

  const { data, hasNextPage, fetchNextPage, isFetching, isLoading } = useInfiniteFetchFeedsApi({
    initialPageParam: {
      page: 1,
    },
    params: {
      itemsPerPage: 50,
      tenantIds: [selectedTenant.id],
      ...(search && { search }),
      ...(currentSegment !== NotificationSegment.ALL && { categories: [currentSegment] }),
    },
    staleTime: 60 * 1000,
  })

  const setSearchDebounced = useDebounceFn((search?: string) => setSearch(search?.trim() || ''), Delay.Search)

  const [loadMoreRef, setLoadMoreRef] = useState<HTMLDivElement>(null!)
  const setRef: RefCallback<HTMLDivElement> = useCallback(node => setLoadMoreRef(node!), [])

  useInfiniteFetchNextPage({
    loadMoreRef,
    isFetching,
    fetchNextPage,
    hasNextPage,
  })

  const normalizedStatus = useMemo(() => normalize(statuses, status => status.tenantId), [statuses])

  const feedsDataReloadHandler = async () => {
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.USER_FEEDS_INFINITE] })
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  }

  const updateLastSeenAt = async () => {
    await handleUpdateFeedsLastSeenAt(selectedTenant.id)
    await queryClient.invalidateQueries({ queryKey: [ApiQueryKeys.FEEDS_STATUSES] })
  }

  const handleFeedSelect = (id?: string) => {
    setSelectedFeeds(selectedFeeds => {
      if (!id) {
        return []
      }
      return selectedFeeds.includes(id) ? selectedFeeds.filter(feedId => feedId !== id) : [...selectedFeeds, id]
    })
  }

  const handleFeedsDelete = async (ids?: string[]) => {
    try {
      await handleDeleteBulkFeeds({ tenantId: selectedTenant.id, ids: ids ?? null })
      await feedsDataReloadHandler()
      enqueueToast({
        message: t('os.notification.toast.delete_success_many'),
        type: 'success',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      setSelectedFeeds([])
    }
  }

  const handleFeedsReadStatus = async (ids?: string[]) => {
    try {
      await handleMarkAsRead({ tenantId: selectedTenant.id, ids: ids ?? null })
      await feedsDataReloadHandler()
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      setSelectedFeeds([])
    }
  }

  const handleSearchButtonClick = () => {
    setIsSearchDisplayed(isSearchDisplayed => !isSearchDisplayed)
    setSearch('')
  }

  const handleSearchChange = (event: CustomEvent<InputChangeEventDetail>) => setSearchDebounced(event.detail.value)

  return (
    <NotificationFeedsStateContext.Provider value={{ selectedFeeds, handleFeedSelect }}>
      <SideModal
        data-testid={id}
        open={isOpen}
        className={styles.modal}
        size="m"
        onWppSideModalClose={onClose}
        onWppSideModalCloseComplete={() => {
          onCloseComplete()
          updateLastSeenAt()
          feedsDataReloadHandler()
        }}
      >
        <>
          <Flex slot="header" justify="between" align="center">
            <Flex align="center">
              {!!availableTenantsWithoutCurrent.length && (
                <WppCardGroup value={selectedTenant.name} withRadioOrCheckbox={false}>
                  <div className={styles.mainTenantWrapper}>
                    <TenantIconWithNotification
                      onClick={() => {
                        updateLastSeenAt()
                        setSelectedTenant(currentTenant)
                        setCurrentSegment(NotificationSegment.ALL)
                      }}
                      tenant={currentTenant}
                      hasNewNotification={normalizedStatus[currentTenant.id]?.existsNew}
                    />
                  </div>
                </WppCardGroup>
              )}
              <WppTypography className={styles.title} type="xl-heading">
                {t('os.notification.title', { count: DEFAULT_PLURAL_COUNT })} {selectedTenant.name}
              </WppTypography>

              <Flex className={styles.actionsWrapper}>
                <WppActionButton
                  variant="secondary"
                  onClick={handleSearchButtonClick}
                  data-testid="notifications-search-button"
                >
                  <WppIconSearch slot="icon-start" />
                </WppActionButton>
                <WppMenuContext
                  dropdownConfig={{
                    appendTo: () => document.body,
                    placement: 'bottom-end',
                  }}
                >
                  <WppActionButton
                    slot="trigger-element"
                    variant="secondary"
                    className={styles.contextButton}
                    data-testid="notifications-general-actions-button"
                  >
                    <WppIconMore direction="horizontal" className={styles.moreIcon} />
                  </WppActionButton>

                  <Flex direction="column" gap={8}>
                    <WppListItem onWppChangeListItem={() => handleFeedsReadStatus()}>
                      <WppIconAvailableCheckmark slot="left" />
                      <span slot="label">{t('os.notification.actions.mark_all_as_read')}</span>
                    </WppListItem>

                    <WppDivider />

                    <WppListItem onWppChangeListItem={() => handleFeedsDelete()}>
                      <WppIconTrash slot="left" />
                      <span slot="label">{t('os.notification.actions.delete_all')}</span>
                    </WppListItem>
                  </Flex>
                </WppMenuContext>
              </Flex>
            </Flex>
          </Flex>

          <Flex slot="body" gap={10} className={styles.body}>
            {!!availableTenantsWithoutCurrent.length && (
              <WppCardGroup
                value={selectedTenant.name}
                withRadioOrCheckbox={false}
                data-testid="tenants-list-without-current-tenant"
              >
                <Flex
                  direction="column"
                  gap={12}
                  className={styles.allTenantsWrapper}
                  data-testid="tenants-list-scrollable"
                >
                  {availableTenantsWithoutCurrent.map(tenant => (
                    <TenantIconWithNotification
                      key={tenant.id}
                      onClick={() => {
                        updateLastSeenAt()
                        setSelectedTenant(tenant)
                        setCurrentSegment(NotificationSegment.ALL)
                      }}
                      tenant={tenant}
                      hasNewNotification={normalizedStatus[tenant.id]?.existsNew}
                    />
                  ))}
                </Flex>
              </WppCardGroup>
            )}
            <Flex direction="column" gap={24} className={styles.bodyContent}>
              {selectedFeeds.length ? (
                <Flex justify="between">
                  <WppPill
                    label={`${selectedFeeds.length} selected`}
                    removable
                    type="display"
                    onWppClose={() => handleFeedSelect()}
                    data-testid="selected-notifications-counter"
                  />
                  <Flex gap={4}>
                    <WppActionButton
                      onClick={() => handleFeedsReadStatus(selectedFeeds)}
                      data-testid="notifications-bulk-mark-as-read-button"
                    >
                      {t('os.notification.actions.mark_as_read')}
                    </WppActionButton>
                    <WppActionButton
                      onClick={() => handleFeedsDelete(selectedFeeds)}
                      data-testid="notifications-bulk-delete-button"
                    >
                      {t('os.notification.actions.delete')}
                    </WppActionButton>
                  </Flex>
                </Flex>
              ) : (
                <Fragment>
                  {isSearchDisplayed ? (
                    <WppInput
                      type="search"
                      size="s"
                      placeholder={t('os.notification.search_placeholder')}
                      onWppChange={handleSearchChange}
                      data-testid="notifications-search-input"
                    />
                  ) : (
                    <NotificationsSegmentedControl
                      value={currentSegment}
                      onWppChange={({ detail: { value } }) => {
                        setCurrentSegment(value as NotificationSegment)
                      }}
                    />
                  )}
                </Fragment>
              )}
              <Flex direction="column" gap={10} className={styles.bodyNotifications}>
                <NotificationItems
                  feeds={data}
                  lastSeenAt={normalizedStatus[selectedTenant.id]?.lastSeenAt}
                  selectedTenant={selectedTenant}
                />

                {isLoading && <Skeleton />}

                <Flex justify="center" ref={setRef}>
                  {isFetching && !isLoading && <WppSpinner size="m" />}
                </Flex>

                {!isLoading && !isFetching && !data.length && (
                  <Flex className={styles.emptyState} justify="center">
                    {search ? <EmptySearchState search={search} /> : <EmptyState />}
                  </Flex>
                )}
              </Flex>
            </Flex>
          </Flex>
        </>
      </SideModal>
    </NotificationFeedsStateContext.Provider>
  )
}

export const {
  showModal: showNotificationsSideModal,
  hideModal: hideNotificationsSideModal,
  useModal: useNotificationSideModal,
} = createNiceModal(NotificationsSideModal, 'notifications-side-modal')
