import { Theme } from '@platform-ui-kit/components-library'
import {
  MayBeNull,
  AttachmentMetadataResponse,
  AttachmentMetadata,
  HierarchyLevel,
  TenantAzMeta,
  TenantFlags,
  TenantConfig,
  Taxonomy,
  HierarchyLevelType,
} from '@wpp-open/core'

import { IdentityProvider } from 'types/auth/identityProvider'

export enum HostType {
  GENERIC = 'generic',
  TENANT = 'tenant',
  UNKNOWN = 'unknown',
}

export interface UnknownHostInfo {
  type: HostType.UNKNOWN
  currentTenant: null
}

export interface GenericHostInfo {
  type: HostType.GENERIC
  currentTenant: null
}

export interface TenantHostInfo {
  type: HostType.TENANT
  currentTenant: TenantPublic
}

export type KnownHostInfo = GenericHostInfo | TenantHostInfo

export type HostInfo = UnknownHostInfo | KnownHostInfo

export interface TenantShort {
  id: string
  name: string
  azId: string
  azMeta: TenantAzMeta
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  subdomain: string
  homeUrl: string
  flags: TenantFlags
}

export enum SupportService {
  Zendesk = 'zendesk',
  Intercom = 'intercom',
}

export interface TenantPublic {
  id: string
  name: string
  title: MayBeNull<string>
  description: MayBeNull<string>
  favicon: MayBeNull<AttachmentMetadataResponse>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  loginBackground: MayBeNull<AttachmentMetadataResponse>
  subdomain: string
  supportService: SupportService
  homeUrl: string
}

export type TenantFormHierarchyLevel<T extends HierarchyLevelType = HierarchyLevelType> = Omit<
  HierarchyLevel<T>,
  'custom'
>

export interface TenantFormEditDTO {
  name: string
  title: MayBeNull<string>
  description: MayBeNull<string>
  favicon: MayBeNull<AttachmentMetadata>
  logoThumbnail: MayBeNull<AttachmentMetadata>
  logoOriginal: MayBeNull<AttachmentMetadata>
  loginBackground: MayBeNull<AttachmentMetadata>
  azId: string
  subdomain: string
  hierarchyLevels: TenantFormHierarchyLevel[]
  flags: Partial<Omit<TenantFlags, 'isPrimary'>>
  config: TenantConfig
}

export interface TenantPublicShort {
  id: string
  name: string
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  subdomain: string
  homeUrl: string
  flags: TenantFlags
}

export interface TenantDefault {
  title: string
  description: string
  favicon: MayBeNull<AttachmentMetadataResponse>
  logoThumbnail: MayBeNull<AttachmentMetadataResponse>
  logoOriginal: MayBeNull<AttachmentMetadataResponse>
  loginBackground: MayBeNull<AttachmentMetadataResponse>
  theme: Theme
  taxonomy: Taxonomy
}

export interface TenantPrimaryUser {
  email: string
  firstName: string
  lastName: string
  avatarOriginal: MayBeNull<AttachmentMetadata>
  avatarThumbnail: MayBeNull<AttachmentMetadata>
  jobTitle: string
  department: MayBeNull<string>
  country: MayBeNull<string>
  countryAlpha2Code: MayBeNull<string>
}

export interface TenantIdpFormEditDTO {
  title: string
  enabled: boolean
}

export interface TenantIdp {
  id: string
  alias: TenantIdpAlias
  tenantId: MayBeNull<string>
  defaultTenantId: MayBeNull<string>
  title: string
  logo: MayBeNull<AttachmentMetadataResponse>
  enabled: boolean
  legacyIDP?: IdentityProvider
}

export enum TenantIdpAlias {
  Okta = 'Okta',
  GroupM = 'GroupM',
  MagicLink = 'Magic link',
  Email = 'Email',
  SignUp = 'Sign up',
}

export interface TenantTaxonomy {
  data: Taxonomy
}
