import {
  AppErrorType,
  HierarchyNodeType,
  MayBeNull,
  NavigationTree,
  OpenAppHandler,
  SelectedWorkspace,
  SelectedWorkspaceItem,
  TriggerErrorHandler,
} from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { RenderErrorType, useDispatchRenderError } from 'components/renderError/utils'
import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import { queryClient } from 'providers/osQueryClient/utils'
import { getAppInstanceUrl } from 'utils/navigation'
import { resolveActiveHierarchy } from 'utils/workspace'

export const resolveContextHierarchy = ({
  activeWorkspaceId,
  navigationTree,
}: {
  activeWorkspaceId: MayBeNull<string>
  navigationTree: NavigationTree
}): MayBeNull<SelectedWorkspace> => {
  if (!activeWorkspaceId) {
    return null
  }

  const activeHierarchy = resolveActiveHierarchy({
    validHierarchyId: activeWorkspaceId,
    navigationTree,
  })

  return {
    azId: activeWorkspaceId,
    mapping: Object.fromEntries(
      activeHierarchy.map((nodeAzId, index) => [
        nodeAzId,
        getHierarchyStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : activeHierarchy[index - 1],
          navigationTree,
        }),
      ]),
    ),
  }
}

const getHierarchyStructureItem = ({
  azId,
  parentAzId,
  navigationTree,
}: {
  azId: string
  parentAzId?: string
  navigationTree: NavigationTree
}): SelectedWorkspaceItem => {
  const node = navigationTree.mapping[azId]

  return {
    azId,
    name: node.name,
    type: node.type as HierarchyNodeType,
    customTypeName: node.customTypeName,
    parentAzId,
  }
}

export const useOpenApp = (): OpenAppHandler => {
  const navigate = useNavigate()

  return useStableCallback(async (appInstanceId: string) => {
    if (!appInstanceId) {
      throw new Error('OpenAppHandler: appInstanceId is empty')
    }

    const appUrl = getAppInstanceUrl({ id: appInstanceId })

    await queryClient.resetQueries({ queryKey: [ApiQueryKeys.APP_INSTANCE, { id: appInstanceId }] })

    navigate(appUrl)
  })
}

const mapAppErrorType = (errorType: AppErrorType): RenderErrorType => {
  switch (errorType) {
    case AppErrorType.ForbiddenPage:
      return RenderErrorType.ForbiddenPage
    case AppErrorType.CriticalError:
      return RenderErrorType.CriticalError
    default:
      throw new Error('Unsupported AppErrorType')
  }
}

export const useTriggerError = (): TriggerErrorHandler => {
  const dispatchError = useDispatchRenderError()

  return useStableCallback(async (errorType: AppErrorType) => {
    dispatchError(mapAppErrorType(errorType))
  })
}
