import { WppActionButton, WppIconChevron, WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { useGenerateUserDetailsDownloadUrlsApi } from 'api/attachments/queries/useGenerateUserDetailsDownloadUrlsApi'
import { useUserByEmailApi } from 'api/users/queries/useUserByEmailApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/notificationItemSideModal/NotificationItemSideModal.module.scss'
import { HierarchyApprovedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyApprovedSideModalContent'
import { HierarchyRejectedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyRejectedSideModalContent'
import { HierarchyRequestedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/HierarchyRequestedSideModalContent'
import { TenantRequestedSideModalContent } from 'components/notificationItemSideModal/notificationSideModalContent/TenantRequestedSideModalContent'
import { Markdown } from 'components/notificationsSideModal/Markdown'
import { NotificationHeader } from 'components/notificationsSideModal/notificationHeader/NotificationHeader'
import {
  hideNotificationsSideModal,
  showNotificationsSideModal,
  useNotificationSideModal,
} from 'components/notificationsSideModal/NotificationsSideModal'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { EventName, Feed } from 'types/feeds/feeds'
import { createNiceModal, NiceModalWrappedProps } from 'utils/createNiceModal'

type Props = NiceModalWrappedProps & {
  notification: Feed
  logoUrl?: MayBeNull<string>
}

const NotificationItemSideModal = ({ isOpen, onClose, onCloseComplete, id, notification, logoUrl }: Props) => {
  const { t } = useTranslation()
  const { context, createdAt, eventName } = notification
  const { user_email, request_reason, reject_reason, tenant_name, body, title } = context
  const { visible: notificationsSideModalVisible } = useNotificationSideModal()

  const { data: user } = useUserByEmailApi({
    params: { email: user_email },
    enabled: isOpen && !!user_email,
  })

  const {
    data: [avatar],
    isLoading: isAvatarLoading,
  } = useGenerateUserDetailsDownloadUrlsApi({
    params: { keys: [user?.avatarThumbnail?.key!] },
    enabled: isOpen && !!user?.avatarThumbnail,
  })

  const handleClose = () => {
    onClose()
    hideNotificationsSideModal()
  }

  const handleBack = () => {
    if (!notificationsSideModalVisible) {
      showNotificationsSideModal()
    }
    onClose()
  }

  return (
    <SideModal
      data-testid={id}
      open={isOpen}
      className={styles.modal}
      size="m"
      onWppSideModalClose={handleClose}
      onWppSideModalCloseComplete={onCloseComplete}
    >
      <Flex slot="header" justify="between" align="center">
        <Flex align="center" gap={8}>
          <WppActionButton
            className={styles.backButton}
            onClick={handleBack}
            data-testid="notification-item-back-button"
          >
            <WppIconChevron direction="left" color="var(--wpp-grey-color-1000)" />
          </WppActionButton>
          <WppTypography className={styles.title} type="xl-heading" data-testid="notification-item-header">
            {t('os.notification.title')}
          </WppTypography>
        </Flex>
      </Flex>

      <Flex slot="body" gap={10}>
        <Flex direction="column" gap={20} className={styles.bodyContent}>
          <Flex align="center" gap={12}>
            <Avatar size="xs" name={tenant_name} src={logoUrl || undefined} data-testid="notification-item-logo" />
            <NotificationHeader title={title} createdAt={createdAt} />
          </Flex>

          {eventName === EventName.HIERARCHY_ACCESS_REQUESTED_ADMIN && (
            <HierarchyRequestedSideModalContent
              isOpen={isOpen}
              context={context}
              isAvatarLoading={isAvatarLoading}
              src={avatar?.signed_url}
            />
          )}
          {eventName === EventName.HIERARCHY_ACCESS_APPROVED && (
            <HierarchyApprovedSideModalContent requestReason={request_reason} />
          )}
          {eventName === EventName.HIERARCHY_ACCESS_REJECTED && (
            <HierarchyRejectedSideModalContent rejectReason={reject_reason} requestReason={request_reason} />
          )}
          {eventName === EventName.TENANT_ACCESS_REQUESTED_ADMIN && (
            <TenantRequestedSideModalContent
              isOpen={isOpen}
              context={context}
              isAvatarLoading={isAvatarLoading}
              src={avatar?.signed_url}
            />
          )}
          {eventName === EventName.ADMIN_NOTIFICATION_REQUEST && body && (
            <WppTypography className={styles.message} type="s-body" data-testid="notification-item-content-custom">
              {body}
            </WppTypography>
          )}

          {eventName === EventName.APPS_NOTIFICATION && body && (
            <WppTypography className={styles.message} type="s-body" data-testid="notification-item-content-apps">
              <Markdown markdown={body} />
            </WppTypography>
          )}
        </Flex>
      </Flex>
    </SideModal>
  )
}

export const { showModal: showNotificationItemSideModal, useModal: useNotificationItemSideModal } = createNiceModal(
  NotificationItemSideModal,
  'notification-item-side-modal',
)
