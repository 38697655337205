import { TreeType } from '@platform-ui-kit/components-library'
import { WppInlineMessage, WppSkeleton, WppTree } from '@platform-ui-kit/components-library-react'
import { ComponentPropsWithoutRef, forwardRef, useImperativeHandle, useRef } from 'react'
import { mergeRefs } from 'react-merge-refs'

import { Flex } from 'components/common/flex/Flex'
import { useField } from 'hooks/form/useField'

interface Props extends Omit<ComponentPropsWithoutRef<typeof WppTree>, 'id'> {
  name: string
  'data-testid'?: string
}

export const FormTree = forwardRef<HTMLWppTreeElement, Props>(
  ({ name, onWppChange, 'data-testid': dataTestId, ...rest }, ref) => {
    const {
      field: { ref: fieldRef },
      fieldState: { error },
    } = useField({
      name,
    })

    const innerRef = useRef<HTMLWppTreeElement>(null)

    useImperativeHandle(
      fieldRef,
      () => ({
        focus: () => innerRef.current?.focus(),
      }),
      [],
    )

    const errorText = error?.message

    return (
      <Flex direction="column">
        {!!errorText && <WppInlineMessage data-testid="tree-error-message" message={errorText} type="error" />}
        <WppTree
          {...rest}
          ref={mergeRefs([innerRef, ref])}
          id={name}
          onWppChange={({ detail, ...rest }) => {
            onWppChange?.({ detail, ...rest })
          }}
          data-testid={dataTestId}
        />
      </Flex>
    )
  },
)

export const FormTreeSkeleton = () => (
  <Flex direction="column" gap={6}>
    <WppSkeleton width="30%" height={22} />
    <Flex direction="column" align="end" gap={6}>
      <WppSkeleton height={20} width="100%" />
      <WppSkeleton height={20} width="95%" />
      <WppSkeleton height={20} width="90%" />
    </Flex>
  </Flex>
)

export const updateTreeById = (tree: TreeType[], id: string | number, newItem: Partial<TreeType>): TreeType[] =>
  tree.map((item: TreeType) => {
    return item.id === id
      ? { ...item, ...newItem }
      : item.children?.length
        ? {
            ...item,
            open: item.open || areAnyChildrenSelected(item.children),
            children: updateTreeById(item.children, id, newItem),
          }
        : item
  })

export const updateTreeCloseNotSelected = (tree: TreeType[]): TreeType[] => {
  return tree.map((item: TreeType) => {
    return item.children?.length
      ? {
          ...item,
          open: item.title === 'OS' || areAnyChildrenSelected(item.children),
          hidden: false,
          children: updateTreeCloseNotSelected(item.children),
        }
      : { ...item, open: areAnyChildrenSelected([item]), hidden: false }
  })
}

export interface Navigation {
  name: string
  id: string
  selected?: boolean
}

interface NavigationData {
  currentItem: TreeType
  navigation: Navigation[]
  isFilter?: boolean
}

export const getNavigationData = ({ currentItem, navigation, isFilter }: NavigationData) => {
  return currentItem.selected
    ? [...navigation, { name: currentItem.title, id: currentItem.id.toString(), ...(isFilter && { selected: true }) }]
    : navigation.filter(item => item.id !== currentItem?.id)
}

export const updateTreeBySearch = (tree: TreeType[], search: string): TreeType[] =>
  search
    ? tree.map((item: TreeType) => {
        return item.children?.length
          ? {
              ...item,
              open: item.open || areItemOrAnyChildrenSearched(item.children, search),
              hidden: !areItemOrAnyChildrenSearched([item], search),
              children: updateTreeBySearch(item.children, search),
            }
          : { ...item, hidden: !item.title.toLowerCase().includes(search.toLowerCase()) }
      })
    : tree

const areAnyChildrenSelected = (treeData: TreeType[]): boolean =>
  treeData.some(({ selected, children = [] }: TreeType) => selected || areAnyChildrenSelected(children))

const areItemOrAnyChildrenSearched = (treeData: TreeType[], search: string): boolean =>
  treeData.some((item: TreeType) => {
    return (
      item.title.toLowerCase().includes(search.toLowerCase()) ||
      areItemOrAnyChildrenSearched(item.children || [], search)
    )
  })

export const disableTreeItems = (
  tree: TreeType[],
  idsForDasable: string[],
  isFirstLevelHidden?: boolean,
): TreeType[] => {
  const resultTree =
    isFirstLevelHidden && tree[0].children?.length
      ? tree[0].children.map((item: TreeType) => ({
          ...item,
          iconStart: { icon: 'wpp-icon-warning', name: '' },
          isNotSelectable: true,
        }))
      : tree

  if (!idsForDasable.length) {
    return resultTree
  }

  return resultTree.map((item: TreeType) => {
    return item.children?.length
      ? {
          ...item,
          ...(idsForDasable.some(id => id === item.id) && {
            iconStart: { icon: 'wpp-icon-warning', name: '' },
          }),
          isNotSelectable: item.isNotSelectable || idsForDasable.some(id => id === item.id),
          children: disableTreeItems(item.children, idsForDasable),
        }
      : {
          ...item,
          isNotSelectable: idsForDasable.some(id => id === item.id),
          ...(idsForDasable.some(id => id === item.id) && {
            iconStart: { icon: 'wpp-icon-warning', name: '' },
          }),
        }
  })
}
