import { NoCodeAppType } from '@wpp-open/core'

import { DefaultNoCodeAppRenderer } from 'pages/appsNavigation/noCodeApp/defaultNoCodeAppRenderer/DefaultNoCodeAppRenderer'
import { MiroBoardAppRenderer } from 'pages/appsNavigation/noCodeApp/miroBoardAppRenderer/MiroBoardAppRenderer'
import styles from 'pages/appsNavigation/noCodeApp/NoCodeApp.module.scss'
import { RenderableNoCodeApp } from 'types/apps/noCodeApps'

interface Props {
  app: RenderableNoCodeApp
}

export const NoCodeApp = ({ app }: Props) => (
  <div className={styles.root}>
    {app.type === NoCodeAppType.MiroBoard ? <MiroBoardAppRenderer app={app} /> : <DefaultNoCodeAppRenderer app={app} />}
  </div>
)
