import { WppMenuContext } from '@platform-ui-kit/components-library-react'
import { AppInstanceAssignmentType, MayBeNull } from '@wpp-open/core'
import { ComponentPropsWithoutRef, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { useAppInstancesApi } from 'api/apps/queries/useAppInstancesApi'
import { Flex } from 'components/common/flex/Flex'
import { AppInstancesDefaults } from 'constants/appInstances'
import { PageSize } from 'constants/pageSize'
import { ProjectBreadcrumbsAppsProvider } from 'layout/header/breadcrumbs/project/appsProvider/ProjectBreadcrumbsAppsProvider'
import { useOsState } from 'providers/osState/OsStateProvider'
import { mapAppInstanceToApp } from 'utils/appInstances'

type WppMenuContextProps = Omit<ComponentPropsWithoutRef<typeof WppMenuContext>, 'children'>

type TippyInstance = Parameters<Required<Required<WppMenuContextProps>['dropdownConfig']>['onShow']>[0]

interface Props extends WppMenuContextProps {
  trigger: ReactNode
  menu: ReactNode
}

export const ProjectBreadcrumbsMenuContext = ({ trigger, menu, listWidth, dropdownConfig, ...rest }: Props) => {
  const { appData } = useOsState()
  const [isOpen, setIsOpen] = useState(false)
  const tippyInstanceRef = useRef<MayBeNull<TippyInstance>>(null)

  const { project } = appData

  const { data: appInstances, isLoading } = useAppInstancesApi({
    params: {
      assignmentId: project?.id,
      assignmentType: AppInstanceAssignmentType.Project,
      itemsPerPage: PageSize.All,
    },
    staleTime: AppInstancesDefaults.BreadcrumbsStaleTime,
    enabled: isOpen && !!project,
  })

  // Update dropdown positioning on content change.
  // Applies only if list width is dynamic.
  useEffect(() => {
    if (!listWidth) {
      tippyInstanceRef.current?.popperInstance?.update()
    }
  }, [isLoading, listWidth])

  const appsMap = useMemo(
    () => Object.fromEntries(appInstances.map(appInstance => [appInstance.id, mapAppInstanceToApp(appInstance)])),
    [appInstances],
  )

  const wrappedDropdownConfig: WppMenuContextProps['dropdownConfig'] = useMemo(
    () => ({
      ...dropdownConfig,
      onShow: instance => {
        tippyInstanceRef.current = instance

        setIsOpen(true)

        dropdownConfig?.onShow?.call(null, instance)
      },
      onHidden: instance => {
        setIsOpen(false)

        dropdownConfig?.onHidden?.call(null, instance)
      },
    }),
    [dropdownConfig],
  )

  return (
    <WppMenuContext {...rest} listWidth={listWidth} dropdownConfig={wrappedDropdownConfig}>
      {trigger}

      <Flex direction="column">
        <ProjectBreadcrumbsAppsProvider appsMap={appsMap} isLoading={isLoading}>
          <>{isOpen ? menu : null}</>
        </ProjectBreadcrumbsAppsProvider>
      </Flex>
    </WppMenuContext>
  )
}
