export enum ApiQueryKeys {
  CURRENT_USER = 'current-user',
  USER_BY_EMAIL = 'user-by-email',
  PRIMARY_USERS_LIST = 'primary-users-list',
  USERS_LIST = 'users-list',
  USERS_LIST_BY_EMAILS = 'users-list-by-emails',
  USER_PERMISSIONS = 'user-permissions',
  AZ_USER_BY_EMAIL = 'az-user-by-email',
  USER_PROFILE_COUNTRIES_LIST = 'user-profile-countries-list',

  WIDGETS = 'widgets',
  WIDGET_TEMPLATES = 'widget-templates',
  PROJECT_TRACKING_STATISTICS_NEWLY_CREATED = 'PROJECT_TRACKING_STATISTICS_NEWLY_CREATED',
  PROJECT_TRACKING_STATISTICS_TASKS_OVERALL = 'PROJECT_TRACKING_STATISTICS_TASKS_OVERALL',
  PROJECT_TRACKING_STATISTICS_TASKS_RISKS = 'PROJECT_TRACKING_STATISTICS_TASKS_RISKS',
  PROJECT_TRACKING_STATISTICS_PROJECTS_OVERALL = 'PROJECT_TRACKING_STATISTICS_PROJECTS_OVERALL',
  PROJECT_TRACKING_STATISTICS_TASKS_OVERDUE = 'PROJECT_TRACKING_STATISTICS_TASKS_OVERDUE',

  NEWS = 'news',
  RECENT_CLIENTS = 'recent-clients',

  CREDENTIALS = 'credentials',

  WORKSPACE_AGENCIES = 'workspace-agencies',

  TENANT_ROLES_INFINITE = 'tenant-roles-infinite',

  COUNTRIES_INFINITE = 'countries-infinite',
  JOB_TITLES_INFINITE = 'job-titles-infinite',
  DEPARTMENTS_INFINITE = 'departments-infinite',
  USERS_LIST_INFINITE = 'users-list-infinite',

  WORKSPACE = 'workspace',
  MY_WORKSPACES = 'my-workspaces',
  OTHER_WORKSPACES = 'other-workspaces',
  WORKSPACE_WITH_ANCESTORS = 'workspace-with-ancestors',
  LINKS = 'links',
  DEVHUB_APPS = 'devhub-apps',
  APP_INSTANCES = 'app-instances',
  APP_INSTANCE = 'app-instance',
  LEAN_APPS = 'lean-apps',
  DEVHUB_APPS_INFINITE = 'devhub-apps-infinite',
  LEAN_APP = 'lean-app',
  APP_INSTANCES_PRELAUNCHED = 'app-instances-prelaunched',
  PAGE_BUILDER_ASSET = 'page-builder-asset',
  NAVIGATION_NODES = 'navigation-nodes',

  AVAILABLE_TENANTS = 'available-tenants',
  ALL_TENANTS = 'all-tenants',
  TENANT = 'tenant',
  TENANT_IDPS = 'tenant-idps',
  DEFAULT_IDPS = 'default-tenant-idps',

  HOST_INFO = 'host-info',
  CURRENT_TENANT_THEME = 'current-tenant/tenant-theme',
  DEFAULT_TENANT = 'default-tenant',

  NAVIGATION_TREE = 'navigation-tree',
  HIERARCHY_TREE = 'hierarchy-tree',
  NAVIGATION_ACCESS = 'navigation-access',
  NAVIGATION_GROUPS = 'navigation-groups',
  APP_INSTANCE_GROUPS = 'app-instance-groups',
  APP_INSTANCE_SUB_GROUPS = 'app-instance-sub-groups',

  MASTER_DATA_DOWNLOAD_URLS = 'master-data-download-urls',
  USER_DETAILS_DOWNLOAD_URLS = 'user-details-download-urls',

  GROUPS_LIST_INFINITE = 'groups-list-infinite',
  GROUPS_LIST = 'groups-list',
  GROUP = 'group',
  GROUP_USERS = 'group-users',
  GROUP_ROLES = 'group-roles',

  ROLE = 'role',
  ROLES = 'roles',
  ROLES_LIST = 'roles-list',
  ROLE_MEMBERS = 'role-members',
  ROLE_MEMBERS_INFINITE = 'role-members-infinite',
  ROLES_SOURCES_INFINITE = 'roles-sources-infinite',

  MEMBER_ROLES = 'member-roles',
  MEMBER_ROLES_EDIT = 'member-roles-edit',

  MASTER_DATA_AGENCIES = 'master-data-agencies',
  MASTER_DATA_BRANDS = 'master-data-brands',
  MASTER_DATA_CLIENTS = 'master-data-clients',
  MASTER_DATA_INDUSTRIES = 'master-data-industries',
  MASTER_DATA_MARKETS = 'master-data-markets',
  MASTER_DATA_REGIONS = 'master-data-regions',

  ORGANIZATION_UNIT = 'organization-unit',
  CLIENT_NODES_INFINITE = 'client-nodes-infinite',
  MARKET_NODES_INFINITE = 'market-nodes-infinite',
  BRAND_NODES_INFINITE = 'brand-nodes-infinite',
  REGION_NODES_INFINITE = 'region-nodes-infinite',
  INDUSTRY_NODES_INFINITE = 'industry-nodes-infinite',
  TENANT_THEME = 'tenant-theme',

  PROJECTS = 'projects',
  WIDGET_PROJECTS = 'widget-projects',
  PROJECT_NAVIGATION = 'project-navigation',
  PROJECT_PHASE = 'project-phase',
  WORKFLOW_TEMPLATES = 'project-api/templates',
  INTERCOM_DATA = 'intercom-data',

  MIRO_CONFIG_STATUS = 'miro-config-status',
  MIRO_CONFIG = 'miro-config',
  MIRO_AUTH_LINK = 'miro-auth-link',
  MIRO_AUTH_ME = 'miro-auth-me',

  ACCESS_REQUEST = 'access-request',
  ACCESS_REQUESTS = 'access-requests',
  ACCESS_REQUESTS_COUNT = 'access-requests-count',
  HIERARCHY_REQUEST = 'hierarchy-request',
  HIERARCHY_REQUESTS = 'hierarchy-requests',
  HIERARCHY_REQUESTS_COUNT = 'hierarchy-requests-count',
  SIGN_UP_REQUESTS = 'sign-up-requests',
  SIGN_UP_REQUESTS_COUNT = 'sign-up-requests-count',

  TAXONOMY = 'taxonomy',

  WIDGET_ASSET = 'widget-asset',

  NOTIFICATIONS = 'notifications',
  NOTIFICATION = 'notification',

  CALENDAR_WIDGET_EVENTS = 'calendar-widget-events',
  CALENDAR_WIDGET_ALL_EVENTS = 'calendar-widget-all-events',
  TIMEZONES = 'timezones',
  TIMEZONE_INFO = 'timezone-info',

  USER_FEEDS = 'user-feeds',
  USER_FEEDS_INFINITE = 'user-feeds-infinite',
  FEEDS_STATUSES = 'feeds-statuses',

  DEV_HUB_DOWNLOAD_URLS = 'dev-hub-download-urls',

  PAGEBUILDER_CONFIG = 'pagebuilder-config',

  LAYERS = 'layers',

  MEMBER_GROUPS = 'member-groups',
}
