import { AppInstanceAssignmentType, AppInstanceShort } from '@wpp-open/core'
import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { skipEmptyParams } from 'api/common/utils'

export type Params = PaginationParams<{
  /**
   * Returns app instances by given ids.
   */
  id?: string[]
  /**
   * Filter app instances by type (exclusive) | (inclusive)
   */
  assignmentType?: AppInstanceAssignmentType | AppInstanceAssignmentType[]
  /**
   * Filter app instances by assignment ids
   */
  assignmentId?: string
  /**
   * Filter app instances by devhub app ids
   */
  devhubAppId?: string[]
  /**
   * Filter app instances by name
   */
  search?: string
  sort?: string
}>

export type AppsInstancesResponse = PaginatedResponse<AppInstanceShort>

export const fetchAppInstancesApi = ({
  id,
  assignmentType,
  assignmentId,
  devhubAppId,
  search,
  sort,
  page,
  itemsPerPage,
}: Params) =>
  facadeApi.get<AppsInstancesResponse>('/app-instances', {
    params: skipEmptyParams({
      page,
      itemsPerPage,
      sort,
      filter: {
        id,
        assignmentType,
        assignmentId,
        devhubAppId,
        search,
      },
    }),
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
